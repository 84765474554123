import config from '../global.js';
import axios from 'axios';

// Grant permission to land
export async function grantPermission(requestId) {

    const token = localStorage.getItem('ok2landJwtToken');
    const userId = localStorage.getItem('ok2landUserId');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Data true
    const data = {
        data: {
                landing_permission: true,
                permission: true,
        }
    };

    // Try grant permission
    try {
        const response = await axios.put( `${config.baseUrl}landings/${requestId}`, data, { headers } );
        // Lógica adicional após o sucesso, se necessário
        return response.data;
    } catch (error) {
        console.error('Error on grantPermission request:', error);
        throw new Error('Ocorreu um erro ao conceder permissão.');
    }
}

// Deny permission to land
export async function denyPermission(requestId) {

    const token = localStorage.getItem('ok2landJwtToken');
    const userId = localStorage.getItem('ok2landUserId');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Data false
    const data = {
        data: {
                landing_permission: false,
                permission: false,
        }
    };

    // Try deny permission
    try {
        const response = await axios.put( `${config.baseUrl}landings/${requestId}`, data, { headers } );
        return response.data;
    } catch (error) {
        console.error('Error on denyPermission request:', error);
        throw new Error('Ocorreu um erro ao negar permissão.');
    }
}

export async function updatePaymentStatus(requestId, status) {

    const token = localStorage.getItem('ok2landJwtToken');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Data true
    const data = {
        data: {
            paymentStatus: status,
        }
    };

    // Try grant permission
    try {
        const response = await axios.put( `${config.baseUrl}landings/${requestId}`, data, { headers } );
        // Lógica adicional após o sucesso, se necessário
        return response.data;
    } catch (error) {
        console.error('Error on updatePaymentStatus request:', error);
        throw new Error('Ocorreu um erro a atualizar o estado de pagamento.');
    }
}

export async function createContact(contactData) {
    try {
        const headers = {
            Authorization: `Bearer ${config.hubspot_api_key}`
        };

        axios.post('https://api.hubapi.com/crm/v3/objects/contacts', contactData, { headers })
            .then(response => {
                console.log('Response:', response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    } catch (error) {
        console.error('Error creating contact hubspot request:', error);
        throw new Error('Ocorreu um erro a criar o contacto hubspot');
    }
}

// Club details post
export async function updateClubDetails(clubName, icaoCode, fiscalAddress, taxNumber, contactPerson, phoneNumber) {

    const token   = localStorage.getItem('ok2landJwtToken');
    const clubId  = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Club data
    const data = {
        data: {
            "name1": clubName,
            "name2": icaoCode,
            "address": fiscalAddress,
            "nif": taxNumber,
            "contact_name": contactPerson,
            "phone": phoneNumber,
        }
    };

    // Update club details
    try {
        const response = await axios.put( `${config.baseUrl}clubs/${clubId}`, data, { headers } );
        return response.data;
    } catch (error) {
        console.error('Error on denyPermission request:', error);
        throw new Error('Ocorreu um erro ao negar permissão.');
    }
}

// Runway details post
export async function updateRunwayDetails(type, direction, latitude, longitude, elevation, length, weight, request) {

    const token   = localStorage.getItem('ok2landJwtToken');
    const clubId  = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Club data
    const data = {
        data: {
            "category": type,
            "direction": direction,
            "lat": latitude,
            "long": longitude,
            "altitude": elevation,
            "length": length,
            "weight": weight,
            "request": request,
        }
    };

    // Update club details
    try {
        const response = await axios.put( `${config.baseUrl}clubs/${clubId}`, data, { headers } );
        return response.data;
    } catch (error) {
        console.error('Error on denyPermission request:', error);
        throw new Error('Ocorreu um erro ao negar permissão.');
    }
}

// Schedule post
export async function updateClubSchedule(schedules) {
    const token = localStorage.getItem('ok2landJwtToken');
    const clubId = localStorage.getItem('ok2landUserClub');
    const headers = { Authorization: `Bearer ${token}` };
    const dayNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    try {
        // Para cada schedule, verifica se existe e decide entre POST ou PUT
        for (const schedule of schedules) {
            console.log('schedule', JSON.stringify(schedule));
            for (const day of schedule.days) {
                console.log('day', day);
                const data = {
                    data: {
                        club: clubId,
                        day_index: day,
                        day: dayNames[day],
                        open: schedule.openTime,
                        close: schedule.closeTime,
                    },
                };

                // Busca por entradas existentes
                const searchResponse = await axios.get(`${config.baseUrl}schedules?filters[club][id][$eq]=${clubId}&filters[day_index][$eq]=${day}`, { headers });
                const existingEntries = searchResponse.data;
                if (existingEntries.data.length > 0) {
                    // Se encontrar, faz um PUT para atualizar a primeira entrada encontrada
                    const entryId = existingEntries.data[0].id;
                    await axios.put(`${config.baseUrl}schedules/${entryId}`, data, { headers });
                } else {
                    // Se não encontrar, faz um POST para criar uma nova entrada
                    await axios.post(`${config.baseUrl}schedules`, data, { headers });
                }
            }
        }

        // Executa ação final após todas as atualizações
        console.log('Atualização dos horários concluída com sucesso!');
        return 'success';
    } catch (error) {
        console.error('Erro ao atualizar os horários:', error);
        throw new Error('Ocorreu um erro ao atualizar os horários.');
    }
}

// Price landing (MTOW) post
export async function updateMtows(mtows) {

    const token   = localStorage.getItem('ok2landJwtToken');
    const clubId  = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Update club details
    try {
        // Para cada schedule, verifica se existe e decide entre POST ou PUT
        for (const mtow of mtows) {
            console.log('mtow', JSON.stringify(mtow));
            const data = {
                data: {
                    club: clubId,
                    weight1: mtow.from,
                    weight2: mtow.to,
                    priceLand: mtow.priceLand,
                    priceTouchGo: mtow.priceTouchGo,
                },
            };

            await axios.post(`${config.baseUrl}mtows`, data, { headers });
        }

        // Executa ação final após todas as atualizações
        console.log('Atualização dos mtows concluída com sucesso!');
        return 'success';
    } catch (error) {
        console.error('Erro ao atualizar os mtows:', error);
        throw new Error('Ocorreu um erro ao atualizar os mtows.');
    }
}

export async function updatePriceMtows(homeBaseDiscount) {

    const token   = localStorage.getItem('ok2landJwtToken');
    const clubId  = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Price parking data
    const data = {
        data: {
            "home_base_discount": homeBaseDiscount,
        }
    };

    // Update club details
    try {
        const response = await axios.put( `${config.baseUrl}clubs/${clubId}`, data, { headers } );
        return response.data;
    } catch (error) {
        console.error('Error on denyPermission request:', error);
        throw new Error('Ocorreu um erro ao negar permissão.');
    }
}

// Price parking post
export async function updatePriceParking(hoursFreeParking, singleEngine, twinEngine, bizzJets, helicopter) {

    const token   = localStorage.getItem('ok2landJwtToken');
    const clubId  = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    // Price parking data
    const data = {
        data: {
            "price_park": singleEngine,
            "price_park_twin": twinEngine,
            "price_park_bizz": bizzJets,
            "price_park_heli": helicopter,
            "hours_free_park": hoursFreeParking,
        }
    };

    // Update club details
    try {
        const response = await axios.put( `${config.baseUrl}clubs/${clubId}`, data, { headers } );
        return response.data;
    } catch (error) {
        console.error('Error on denyPermission request:', error);
        throw new Error('Ocorreu um erro ao negar permissão.');
    }
}

// Services post
export async function updateClubServices(services) {
    const token = localStorage.getItem('ok2landJwtToken');
    const clubId = localStorage.getItem('ok2landUserClub');
    const headers = { Authorization: `Bearer ${token}` };

    try {
        for (const service of services) {
            if(service.attributes.updated){
                if(service.attributes.active && service.attributes.clubServiceId == undefined){
                    //Criar o club service
                    const data = {
                        data: {
                            club: clubId,
                            service: service.id,
                            price: service.attributes.price != '' ? service.attributes.price : 0
                        },
                    };
                    await axios.post(`${config.baseUrl}club-services`, data, { headers });
                }
                else if(service.attributes.active && service.attributes.clubServiceId != undefined){
                    const data = {
                        data: {
                            price: service.attributes.price != '' ? service.attributes.price : 0
                        },
                    };
                    //Atualizar o preço
                    await axios.put(`${config.baseUrl}club-services/${service.attributes.clubServiceId}`, data, { headers });
                }
                else if(!service.attributes.active && service.attributes.clubServiceId != undefined){
                    //Atualizar o preço
                    await axios.delete(`${config.baseUrl}club-services/${service.attributes.clubServiceId}`, { headers });
                }
            }
        }

        // Executa ação final após todas as atualizações
        console.log('Atualização dos serviços concluída com sucesso!');
        return 'success';
    } catch (error) {
        console.error('Erro ao atualizar os horários:', error);
        throw new Error('Ocorreu um erro ao atualizar os horários.');
    }
}
