// Global imports
import React, { useEffect, useState } from 'react';
import { getLandings, getUsers, getAirplanes } from '../helpers/getinfo.js';
import { denyPermission, grantPermission } from '../helpers/postinfo.js';
import { isAuthenticated } from '../helpers/authentication.js';

// Import components
import Bell from '../resources/images/bell.svg';
import Airplane from '../resources/images/airplane.svg';
import Takeoff from '../resources/images/airplane-takeoff.png';
import TakeoffRed from '../resources/images/airplane-takeoff_red.png';
import planeIcon from '../resources/images/plane-icon.png';
import HeliIcon from '../resources/images/helicopter-icon.png';
import planeIconWhite from '../resources/images/plane-icon-white.png';
import HeliIconWhite from '../resources/images/helicopter-icon-white.png';
import RightArrow from '../resources/images/right-arrow.svg';
import UpArrow from '../resources/images/up-arrow.svg';
import Refuel from '../resources/images/refuel.svg';
import Cleaning from '../resources/images/clean.svg';
import Maintenence from '../resources/images/maintenence.svg';
import Pilot from '../resources/images/Capitan.svg';
import People from '../resources/images/People.svg';

function AircraftMovements() {
  useEffect(() => {
    if (!isAuthenticated()) {
      alert('You are not logged in!')
      window.location.href = '/';
    }
  }, []);
  
  const [landingsData, setLandingsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [airplanesData, setAirplanesData] = useState([]);
  const [loadingAirplanes, setLoadingAirplanes] = useState(true);
  const [counters, setCounters] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Função para atualizar a largura da janela
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Adiciona o event listener quando o componente é montado
    window.addEventListener('resize', handleResize);

    // Remove o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columnClass = windowWidth > 3000 ? 'col-md-1' : 'col-md-2';

  // Get landings info
  const fetchData = async () => {
    try {
      const landingData = await getLandings();
      setLandingsData(landingData.data);

      const userData = await getUsers();
      setUsersData(userData);

      const airplaneData = await getAirplanes();
      setAirplanesData(airplaneData);

    } catch (error) {
      console.error('Error getting data: ', error);
    } finally {
      setLoadingAirplanes(false); 
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, []);

  const renderAirplanesSelect = () => {
    if ( !loadingAirplanes && airplanesData ) {
      return (
        <select id="plane" value={plane} onChange={handlePlane} required>
          <option value="" disabled>Select an airplane</option>
          {airplanesData.data.map((airplane) => (
            <option key={airplane.id} value={airplane.attributes.name}>
              {airplane.attributes.name}
            </option>
          ))}
        </select>
      );
    } else {
      return <div>Loading...</div>;
    }
  };

  const calculateInitialTime = (ata) => {
    const ataDate = new Date(ata);
    const now = new Date();
    return Math.floor((now - ataDate) / 1000); // Diferença em segundos
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return [hours, minutes, remainingSeconds]
      .map(v => v < 10 ? "0" + v : v)
      .join(":");
  };

  const calculatePrice = (basePrice, initialPrice, seconds) => {
    const hours = Math.floor(seconds / 3600); // Arredonda para baixo para obter apenas horas completas
    return (basePrice + (initialPrice * hours)).toFixed(2); // Multiplica o preço inicial pelo número de horas
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounters((prevCounters) => {
        const newCounters = {...prevCounters};
        for (const key in newCounters) {
          newCounters[key] = newCounters[key] + 1; // Incrementa cada contador em 1 segundo
        }
        return newCounters;
      });
    }, 1000);
  
    return () => clearInterval(interval); // Limpeza
  }, []);

  useEffect(() => {
    const initialCounters = {};
    landingsData.forEach((landing, index) => {
      if (landing.attributes.permission === true && landing.attributes.status === 'parked') {
        initialCounters[index] = calculateInitialTime(landing.attributes.ata);
      }
    });
    setCounters(initialCounters);
  }, [landingsData]); // Dependência: os dados dos landings

  // Handle expand row
  const [expandedItems, setExpandedItems] = useState({});
  const [expandIcon, setExpandIcon] = useState({});

  const toggleExpand = (index) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));

    setExpandIcon((prevState) => ({
      ...prevState,
      [index]: !prevState[index] ? UpArrow : RightArrow,
    }));
  };

  // Handle Add manually form
  const [name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [plane, setPlane] = useState('');
  const [canCreate, setCanCreate] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);

  const showAddLanding = () => {
    setDisplayModal(true);
  };

  const hideAddLanding = () => {
    setDisplayModal(false);
  };

  const handleFullName = (e) => {
    setFullName(e.target.value);
    updateButtonState(name, e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    updateButtonState(email, e.target.value);
  };

  const handleDate = (e) => {
    setDate(e.target.value);
    updateButtonState(date, e.target.value);
  };

  const handlePlane = (e) => {
    setPlane(e.target.value);
    updateButtonState(plane, e.target.value);
  };

  const updateButtonState = (manualFullname, manualEmail, manualDate, manualPlane) => {
    setCanCreate();
  };

  // Service icons
  const serviceIcons = {
    Refuel,
    Cleaning,
    Maintenence,
  };
  
  const getServiceIcon = (serviceName) => {
    const icon = serviceIcons[serviceName];
    return icon || null;
  };

  // Handle accept / deny landing permission
  const [loadingDeny, setLoadingDeny] = useState(false);
  const [loadingGrant, setLoadingGrant] = useState(false);

  // Deny permission
  const handleDenyPermission = (requestId) => {
    setLoadingDeny(true);
  
    denyPermission(requestId)
      .then((result) => {
        console.log('Permission denied successfully', result);
        
        // Update status
        fetchData();
      })
      .catch((error) => {
        console.error('Error denying permission:', error);
      })
      .finally(() => {
        setLoadingDeny(false);
      });
  };

  // Grant permission
  const handleGrantPermission = (requestId) => {
    setLoadingGrant(true);
  
    grantPermission(requestId)
      .then((result) => {
        console.log('Permission granted successfully', result);
        
        // Update status
        fetchData();
      })
      .catch((error) => {
        console.error('Error granting permission:', error);
      })
      .finally(() => {
        setLoadingGrant(false);
      });
  };

  // Check id there's any landing
  const hasPendingLandings = landingsData.some(landing => landing.attributes.permission === false);
  const hasParkedLandings = landingsData.some(landing => landing.attributes.status === 'parked');
  const hasLandings        = landingsData.some(landing => landing.attributes.permission === true);

  return (
    <div className='container ok2land__container'>
        <div className='row ok2land__row'>
          <div className='col-sm-12 col-md-12'>
            <div className='ok2land__aircraft-requests-box'>
              <div className='ok2land__aircraft-title flex-start'>
                <img src={Bell} alt='Requests' />
                <span>REQUESTS</span>
              </div>
              <div className='row ok2land__aircraft-requests-row'>
                <div className='col-md-1 ok2land__aircraft-requests-cols'>
                  <span>TYPE</span>
                </div>
                <div className={`${columnClass} ok2land__aircraft-requests-cols`}>
                  <span>ETA</span>
                </div>
                <div className={`${columnClass} ok2land__aircraft-requests-cols`}>
                  <span>FROM</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-requests-cols'>
                  <span>POB</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-requests-cols'>
                  <span>CALL SIGN</span>
                </div>
                <div className={`${columnClass} ok2land__aircraft-requests-cols`}>
                  <span>PURPOSE</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-requests-cols'>
                  <span>AMOUNT</span>
                </div>
                <div className={`${columnClass} lpad_ppr_title ok2land__aircraft-requests-cols`}>
                  <span>PPR STATUS</span>
                </div>
              </div>
              <div className='row ok2land__aircraft-resquests'>
              {hasPendingLandings &&
                landingsData.map((landing, index) =>
                  landing.attributes.permission === false && landing.attributes.status === 'pending' ? (
                    <div key={index} id={index} className='row ok2land__aircraft-individual-request'>
                      <div className='col-md-1 fix-height'>
                        <div className='takeoff_white'>
                          <img src={landing.attributes.aircraft.data.attributes.category == 'Helicopter' ? HeliIconWhite : planeIconWhite} />
                        </div>
                      </div>
                      <div className={`${columnClass} f14 center__content`}>
                        <div className='date__col'>
                          <span>{new Date(`${landing.attributes.date}T${landing.attributes.time}Z`).toLocaleDateString('en-GB')}</span>
                          <span> • </span>
                          <span>{new Date(`1970-01-01T${landing.attributes.time}Z`).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour24: true })}</span>
                        </div>
                      </div>
                      <div className={`${columnClass} f14 center__content`}>
                        <span>{landing.attributes.from && landing.attributes.from.data && (landing.attributes.from.data.attributes.name1+' '+landing.attributes.from.data.attributes.name2) || '---'}</span>
                      </div>
                      <div className='col-md-1 f14 center__content'>
                        {( JSON.parse(landing.attributes.passengers_on_board).length > 0 ) && (
                          <React.Fragment>
                            <img src={Pilot} alt='Pilot' />
                            <span>1</span>
                          </React.Fragment>
                        )}
                        {JSON.parse(landing.attributes.passengers_on_board).length > 1 && (
                          <img src={People} alt='People' />
                        )}
                        {JSON.parse(landing.attributes.passengers_on_board).length > 1 && (
                          <span>{JSON.parse(landing.attributes.passengers_on_board).length - 1}</span>
                        )}
                      </div>
                      <div className='col-md-1 f14 center__content'>
                        <span>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.callSign || '---'}</span>
                      </div>
                      <div className={`${columnClass} center__content`}>
                        <span>{landing.attributes.landingPurpose == 'training_touch_and_go' ? 'Touch & Go' : 'Landing'}</span>
                      </div>
                      <div className='col-md-1 f16 lpad center__content'>
                        <span>{parseFloat(landing.attributes.price).toFixed(2)}€</span>
                      </div>
                      <div className={`${columnClass} lpad_ppr ppr_buttons center__content`}>
                        <div>
                          <button className='ok2land__btn' name="deny_landing" type="button" onClick={() => handleDenyPermission(landing.id)} disabled={loadingDeny}>REJECT</button>
                          <button className='ok2land__btn1' name="accept_landing" type="button" onClick={() => handleGrantPermission(landing.id)} disabled={loadingGrant}>ACCEPT</button>
                        </div>
                        <img id={`expand__requests_${index}`} onClick={() => toggleExpand(index)} src={expandIcon[index] || RightArrow} alt='Expand' />
                      </div>
                      {expandedItems[index] && (
                        <div className='col-md-12'>
                          <div className='ok2land__row-expanded'>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Aircraft</span>
                              <span className='ok2land__ef-content'>{landing.attributes.aircraft && landing.attributes.aircraft.data && landing.attributes.aircraft.data.attributes.name || '---'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Pilot Name</span>
                              <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.name || '---'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Pilot Email</span>
                              <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.email || '---'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>License #</span>
                              <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.licenceNumber || '---'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Services</span>
                              <span className='ok2land__ef-content'>
                              {landing.attributes.club_services.data.length > 0 && (
                                landing.attributes.club_services.data.map((service, serviceIndex) => (
                                  <React.Fragment key={serviceIndex}>
                                    {getServiceIcon(service.attributes.service.data.attributes.name) ? (
                                      <img src={getServiceIcon(service.attributes.service.data.attributes.name)} alt={service.attributes.service.data.attributes.name} />
                                    ) : (
                                      <span> -- </span>
                                    )}
                                  </React.Fragment>
                                ))
                              )}
                              {landing.attributes.club_services.data.length < 1 && (
                                <span> -- </span>
                              )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )
                )
              }
              {!hasPendingLandings && (
                <div className='row ok2land__aircraft-no-landings'>
                  <div className='col-md-12'>
                    <span>No pending landings.</span>
                  </div>
                </div>
              )}
              </div>
            </div>
            <div className='ok2land__aircraft-upcoming-box'>
              <div className='ok2land__aircraft-title'>
                <div>
                  <img src={Airplane} alt='Upcming landings' />
                  <span>UPCOMING LANDINGS</span>
                </div>
                <button className='ok2land__manual' onClick={showAddLanding} type="submit">ADD MANUALLY</button>
                <div className={`modal__background ${displayModal ? 'show-modal' : ''}`}>
                  <div className='modal__add-manually'>
                    <form className='add__landing'>
                      <div className='add__landing-innerForm'>
                        <h3 className='add__landing-title'>Create Landing</h3>
                        <label>First & Last name</label>
                        <select id="name" value={name} onChange={handleFullName} required>
                          <option value="" disabled>Select a pilot</option>
                          {usersData.map((user) => (
                            <option key={user.id} value={user.username}>
                              {user.username}
                            </option>
                          ))}
                        </select>
                        <label>Email</label>
                        <input type="email" id="email" value={email} onChange={handleEmail} required />
                        <label>Day & Time</label>
                        <input type="date" id="date" value={date} onChange={handleDate} required/>
                        <label>Plane</label>
                        {renderAirplanesSelect()}
                        <div className='add__landing-form-bottom-box'>
                          <span>EST. COST</span>
                          <div className='add__landing-form-bottom'>
                            <button className='ok2land__btn btn_white' onClick={hideAddLanding}>CANCEL</button>
                            <button className='ok2land__btn btn_black' type="submit">CREATE</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='row ok2land__aircraft-upcoming-row'>
                <div className='col-md-1 ok2land__aircraft-upcoming-cols'>
                  <span>TYPE</span>
                </div>
                <div className={`${columnClass} ok2land__aircraft-upcoming-cols`}>
                  <span>ETA</span>
                </div>
                <div className={`${columnClass} ok2land__aircraft-upcoming-cols`}>
                  <span>FROM</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-upcoming-cols'>
                  <span>POB</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-upcoming-cols'>
                  <span>CALL SIGN</span>
                </div>
                <div className={`${columnClass} ok2land__aircraft-upcoming-cols`}>
                  <span>PURPOSE</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-upcoming-cols'>
                  <span>AMOUNT</span>
                </div>
                <div className={`${columnClass} lpad_ppr_title ok2land__aircraft-upcoming-cols`}>
                  <span>PPR STATUS</span>
                </div>
              </div>
              <div className='row ok2land__aircraft-upcoming'>
                {hasLandings &&
                  landingsData.map((landing, index) => (
                    landing.attributes.permission === true && landing.attributes.status === 'pending' ? (
                      <div key={index} id={index} className='row ok2land__aircraft-individual-upcoming'>
                        <div className='col-md-1  fix-height'>
                          <div className='takeoff_red'>
                            <img src={landing.attributes.aircraft.data.attributes.category == 'Helicopter' ? HeliIcon : planeIcon} />
                          </div>
                        </div>
                        <div className={`${columnClass} f14 center__content`}>
                          <div className='date__col'>
                            <span>{new Date(`${landing.attributes.date}T${landing.attributes.time}Z`).toLocaleDateString('en-GB')}</span>
                            <span> • </span>
                            <span>{new Date(`1970-01-01T${landing.attributes.time}Z`).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour24: true })}</span>
                          </div>
                        </div>
                        <div className={`${columnClass} f14 center__content`}>
                          <span>{landing.attributes.from && landing.attributes.from.data && (landing.attributes.from.data.attributes.name1+' '+landing.attributes.from.data.attributes.name2) || '---'}</span>
                        </div>
                        <div className='col-md-1 f14 center__content'>
                          {( JSON.parse(landing.attributes.passengers_on_board).length > 0 ) && (
                            <React.Fragment>
                              <img src={Pilot} alt='Pilot' />
                              <span>1</span>
                            </React.Fragment>
                          )}
                          {JSON.parse(landing.attributes.passengers_on_board).length > 1 && (
                            <img src={People} alt='People' />
                          )}
                          {JSON.parse(landing.attributes.passengers_on_board).length > 1 && (
                            <span>{JSON.parse(landing.attributes.passengers_on_board).length - 1}</span>
                          )}
                        </div>
                        <div className='col-md-1 f14 center__content'>
                          <span>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.callSign || '---'}</span>
                        </div>
                        <div className={`${columnClass} center__content`}>
                          <span>{landing.attributes.landingPurpose == 'training_touch_and_go' ? 'Touch & Go' : 'Landing'}</span>
                        </div>
                        <div className='col-md-1 f16 lpad center__content'>
                          <span>{parseFloat(landing.attributes.price).toFixed(2)}€</span>
                        </div>
                        <div className={`${columnClass} lpad_ppr ppr_buttons center__content`}>
                          <span className='span_ppr'>ACCEPTED</span>
                          <img id={`expand__requests_${index}`} onClick={() => toggleExpand(index)} src={expandIcon[index] || RightArrow} alt='Expand' />
                        </div>
                        {expandedItems[index] && (
                          <div className='col-md-12'>
                            <div className='ok2land__row-expanded'>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Aircraft</span>
                                <span className='ok2land__ef-content'>{landing.attributes.aircraft && landing.attributes.aircraft.data && landing.attributes.aircraft.data.attributes.name || '---'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Pilot Name</span>
                                <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.name || '---'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Pilot Email</span>
                                <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.email || '---'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>License #</span>
                                <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.licenceNumber || '---'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Services</span>
                                <span className='ok2land__ef-content'>
                                {landing.attributes.club_services.data.length > 0 && (
                                  landing.attributes.club_services.data.map((service, serviceIndex) => (
                                    <React.Fragment key={serviceIndex}>
                                      {getServiceIcon(service.attributes.service.data.attributes.name) ? (
                                        <img src={getServiceIcon(service.attributes.service.data.attributes.name)} alt={service.attributes.service.data.attributes.name} />
                                      ) : (
                                        <span> -- </span>
                                      )}
                                    </React.Fragment>
                                  ))
                                )}
                                {landing.attributes.club_services.data.length < 1 && (
                                  <span> -- </span>
                                )}
                                </span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Departure</span>
                                <span className='ok2land__ef-content'>{landing.attributes.departure != null ? new Date(landing.attributes.departure).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour24: true }) : 'N/A'}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )
                  ))
                }
                {!hasLandings && (
                  <div className='row ok2land__aircraft-no-up-landings'>
                    <div className='col-md-12'>
                      <span>No upcoming landings.</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='ok2land__aircraft-upcoming-box'>
              <div className='ok2land__aircraft-title'>
                <div>
                  <img src={Airplane} alt='Upcming landings' />
                  <span>PARKED AIRCRAFTS</span>
                </div>
                <button className='ok2land__manual' onClick={showAddLanding} type="submit">ADD MANUALLY</button>
                <div className={`modal__background ${displayModal ? 'show-modal' : ''}`}>
                  <div className='modal__add-manually'>
                    <form className='add__landing'>
                      <div className='add__landing-innerForm'>
                        <h3 className='add__landing-title'>Create Landing</h3>
                        <label>First & Last name</label>
                        <select id="name" value={name} onChange={handleFullName} required>
                          <option value="" disabled>Select a pilot</option>
                          {usersData.map((user) => (
                            <option key={user.id} value={user.username}>
                              {user.username}
                            </option>
                          ))}
                        </select>
                        <label>Email</label>
                        <input type="email" id="email" value={email} onChange={handleEmail} required />
                        <label>Day & Time</label>
                        <input type="date" id="date" value={date} onChange={handleDate} required/>
                        <label>Plane</label>
                        {renderAirplanesSelect()}
                        <div className='add__landing-form-bottom-box'>
                          <span>EST. COST</span>
                          <div className='add__landing-form-bottom'>
                            <button className='ok2land__btn btn_white' onClick={hideAddLanding}>CANCEL</button>
                            <button className='ok2land__btn btn_black' type="submit">CREATE</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='row ok2land__aircraft-upcoming-row'>
                <div className='col-md-1 ok2land__aircraft-upcoming-cols'>
                  <span>TYPE</span>
                </div>
                <div className='col-md-2 ok2land__aircraft-upcoming-cols'>
                  <span>ATA</span>
                </div>
                <div className='col-md-2 ok2land__aircraft-upcoming-cols'>
                  <span>FROM</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-upcoming-cols'>
                  <span>POB</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-upcoming-cols'>
                  <span>CALL SIGN</span>
                </div>
                <div className='col-md-2 ok2land__aircraft-upcoming-cols'>
                  <span>PURPOSE</span>
                </div>
                <div className='col-md-1 ok2land__aircraft-upcoming-cols'>
                  <span>AMOUNT</span>
                </div>
                <div className='col-md-2 lpad_ppr_title ok2land__aircraft-upcoming-cols'>
                  <span>TIME PARKED</span>
                </div>
              </div>
              <div className='row ok2land__aircraft-upcoming'>
                {hasParkedLandings &&
                  landingsData.map((landing, index) => (
                    landing.attributes.permission === true && landing.attributes.status === 'parked' ? (
                      <div key={index} id={index} className='row ok2land__aircraft-individual-upcoming'>
                        <div className='col-md-1  fix-height'>
                          <div className='takeoff_red'>
                            <img src={landing.attributes.aircraft.data.attributes.category == 'Helicopter' ? HeliIcon : planeIcon} />
                          </div>
                        </div>
                        <div className='col-md-2 f14 center__content'>
                          <div className='date__col'>
                            <span>{new Date(`${landing.attributes.ata}`).toLocaleDateString('en-GB')}</span>
                            <span> • </span>
                            <span>{new Date(`${landing.attributes.ata}`).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour24: true })}</span>
                          </div>
                        </div>
                        <div className='col-md-2 f14 center__content'>
                          <span>{landing.attributes.from && landing.attributes.from.data && (landing.attributes.from.data.attributes.name1+' '+landing.attributes.from.data.attributes.name2) || '---'}</span>
                        </div>
                        <div className='col-md-1 f14 center__content'>
                          {( JSON.parse(landing.attributes.passengers_on_board).length > 0 ) && (
                            <React.Fragment>
                              <img src={Pilot} alt='Pilot' />
                              <span>1</span>
                            </React.Fragment>
                          )}
                          {JSON.parse(landing.attributes.passengers_on_board).length > 1 && (
                            <img src={People} alt='People' />
                          )}
                          {JSON.parse(landing.attributes.passengers_on_board).length > 1 && (
                            <span>{JSON.parse(landing.attributes.passengers_on_board).length - 1}</span>
                          )}
                        </div>
                        <div className='col-md-1 f14 center__content'>
                          <span>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.callSign || '---'}</span>
                        </div>
                        <div className='col-md-2 center__content'>
                          <span>{landing.attributes.landingPurpose == 'training_touch_and_go' ? 'Touch & Go' : 'Landing'}</span>
                        </div>
                        <div className='col-md-1 f16 lpad center__content'>
                          <span>{calculatePrice(parseFloat(landing.attributes.price), parseFloat(landing.attributes.club.data.attributes.price_park), counters[index] || 0)}€</span>
                        </div>
                        <div className='col-md-2 lpad_ppr ppr_buttons center__content'>
                          <span className='time_count'>{formatTime(counters[index] || 0)}</span>
                          <img id={`expand__requests_${index}`} onClick={() => toggleExpand(index)} src={expandIcon[index] || RightArrow} alt='Expand' />
                        </div>
                        {expandedItems[index] && (
                          <div className='col-md-12'>
                            <div className='ok2land__row-expanded'>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Aircraft</span>
                                <span className='ok2land__ef-content'>{landing.attributes.aircraft && landing.attributes.aircraft.data && landing.attributes.aircraft.data.attributes.name || '---'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Pilot Name</span>
                                <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.name || '---'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Pilot Email</span>
                                <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.email || '---'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>License #</span>
                                <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.licenceNumber || '---'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Services</span>
                                <span className='ok2land__ef-content'>
                                {landing.attributes.club_services.data.length > 0 && (
                                  landing.attributes.club_services.data.map((service, serviceIndex) => (
                                    <React.Fragment key={serviceIndex}>
                                      {getServiceIcon(service.attributes.service.data.attributes.name) ? (
                                        <img src={getServiceIcon(service.attributes.service.data.attributes.name)} alt={service.attributes.service.data.attributes.name} />
                                      ) : (
                                        <span> -- </span>
                                      )}
                                    </React.Fragment>
                                  ))
                                )}
                                {landing.attributes.club_services.data.length < 1 && (
                                  <span> -- </span>
                                )}
                                </span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Departure</span>
                                <span className='ok2land__ef-content'>{landing.attributes.departure != null ? new Date(landing.attributes.departure).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour24: true }) : 'N/A'}</span>
                              </div>
                              <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Arrival</span>
                                <span className='ok2land__ef-content'>{landing.attributes.ata != null ? new Date(landing.attributes.ata).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour24: true }) : 'N/A'}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )
                  ))
                }
                {!hasParkedLandings && (
                  <div className='row ok2land__aircraft-no-up-landings'>
                    <div className='col-md-12'>
                      <span>No aircrafts parked.</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default AircraftMovements;