// Global imports
import React, { useEffect, useState } from 'react';
import { getLandings, getHistoryLandings } from '../helpers/getinfo.js';
import { updatePaymentStatus } from '../helpers/postinfo.js';
import { isAuthenticated } from '../helpers/authentication.js';

// Import components
import Airplane from '../resources/images/airplane.svg';
import Vault from '../resources/images/Vault.svg';
import Analytics from '../resources/images/analytics.svg';
import AirplanerED from '../resources/images/airplane-takeoff-red.svg';
import TakeoffRed from '../resources/images/airplane-takeoff_red.png';
import RightArrow from '../resources/images/right-arrow.svg';
import UpArrow from '../resources/images/up-arrow.svg';
import Check from '../resources/images/Check.svg';
import Warning from '../resources/images/Warning.svg';
import Pilot from '../resources/images/Capitan.svg';
import People from '../resources/images/People.svg';

function Dashboard() {
  useEffect(() => {
    if (!isAuthenticated()) {
      alert('You are not logged in!')
      window.location.href = '/';
    }
  }, []);

  
  const [landingsData, setLandingsData] = useState([]);
  const [historyLandingsData, setHistoryLandingsData] = useState([]);
  const [totalPrices, setTotalPrices] = useState(0);

  // Get landings info
  const fetchData = async () => {
    try {
      const landingData = await getLandings();
      setLandingsData(landingData.data);
      const historyLandingData = await getHistoryLandings();
      setHistoryLandingsData(historyLandingData.data);
    } catch (error) {
      console.error('Error getting data: ', error);
    } finally {

    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, []);

  // Handle expand row
  const [expandedItems, setExpandedItems] = useState({});
  const [expandIcon, setExpandIcon] = useState({});

  const toggleExpand = (index) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));

    setExpandIcon((prevState) => ({
      ...prevState,
      [index]: !prevState[index] ? UpArrow : RightArrow,
    }));
  };

  const handlePaymentStatus = async (id, event) => {
    const newStatus = event.target.value;
    console.log('historyLandingsData', historyLandingsData);
    const statusMapping = {
      'PAID': 'Paid',
      'MISSING PAYMENT': 'Pending',
      'CAPTIVE': 'Captive'
    };

    try {
      updateHistoryLandingStatus(id, statusMapping[newStatus]);
      await updatePaymentStatus(id, statusMapping[newStatus]);
    } catch (error) {
      console.error('Erro ao atualizar o status do pagamento:', error);
    }
  }

  const updateHistoryLandingStatus = (id, paymentStatus) => {
    const landingsUpdated = historyLandingsData.map(landing => {
      if (landing.id === id) {
        return {
          ...landing,
          attributes: {
            ...landing.attributes,
            paymentStatus: paymentStatus
          }
        };
      }
      return landing;
    });
    setHistoryLandingsData(landingsUpdated);
  };

  

  const calculateFlightTime = (date1, date2) => {
    // Converter strings para objetos Date
    const data1 = new Date(date1);
    const data2 = new Date(date2);

    // Calcular a diferença em milissegundos
    const diferencaMs = Math.abs(data2 - data1);

    // Converter milissegundos para horas, minutos e segundos
    var horas = Math.floor(diferencaMs / (1000 * 60 * 60));
    var minutos = Math.floor((diferencaMs / (1000 * 60)) % 60);
    var segundos = Math.floor((diferencaMs / 1000) % 60);
    if(horas < 10) horas = '0'+horas;
    if(minutos < 10) minutos = '0'+minutos;
    if(segundos < 10) segundos = '0'+segundos;

    return `${horas}:${minutos}:${segundos}`;
  }

  // Check if there's any landing
  const hasLandings = historyLandingsData.some(landing => landing.attributes.permission === true);

  return (
    <div>
      <div className='container ok2land__container'>
        <div className='row ok2land__row'>
          <div className='col-sm-12 col-md-12 pad-0'>
            <div className='ok2land__dashboard-title-box'>
              <div className='ok2land__dashboard-top-section'>
                <div className='analytics__title'>
                  <img src={Airplane} alt='Upcoming landings' />
                  <span>ANALYTICS</span>
                </div>
                <span className='hide'>CALENDAR</span>
              </div>
              <div className='ok2land__dashboard-top-section'>
                <div className='row ok2land__row dashbord__top-section'>
                  <div className='col-sm-6 col-md-6 ok2land__dashboard-top-section-col'>
                    <div className='ok2land__analytics-col-title'>
                      <span>TOTAL LANDINGS</span>
                      <img src={AirplanerED} alt='Total landings' />
                    </div>
                    <span className='ok2land__analytics-col-result'>
                      {historyLandingsData.filter(landing => landing.attributes.permission === true && new Date(`${landing.attributes.date}T${landing.attributes.time}Z`) < new Date()).length}
                    </span>
                    <div className='ok2land__analytics-col-change'>
                      <img className='hide' src={Analytics} alt='Upcming landings' />
                      <span className='hide'>+1</span>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-6 ok2land__dashboard-top-section-col'>
                    <div className='ok2land__analytics-col-title'>
                      <span>INCOME</span>
                      <img src={Vault} alt='Income' />
                    </div>
                    {historyLandingsData.length > 0 && (
                      <span className='ok2land__analytics-col-result'>
                        {historyLandingsData
                          .filter(landing => landing.attributes.permission === true && new Date(`${landing.attributes.date}T${landing.attributes.time}Z`) < new Date())
                          .reduce((total, current) => total + current.attributes.price, 0)}€
                      </span>
                    )}
                    <div className='ok2land__analytics-col-change'>
                      <img className='hide' src={Analytics} alt='Upcoming landings' />
                      <span className='hide'>+141€</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='ok2land__dashboard-container'>
        <div className='container ok2land__container'>
          <div className='row ok2land__row'>
            <div className='col-sm-12 col-md-12 pad-0'>
              <div className='ok2land__dashboard-history-title'>
                <img src={Airplane} alt='Upcoming landings' />
                <span>LANDING HISTORY</span>
              </div>
              {/*
              <div className='ok2land__dashboard-history-top'>
                <input type="text" name="search" placeholder="Search by name or amount"/>
                <button className='hide' name="download_report">DOWNLOAD REPORT</button>
              </div>
              */}
            </div>
          </div>
          <div className='row ok2land__row mg-top-24 mg-bottom-16 table-header'>
            <div className='col-md-3 ok2land__dashboard-item-cols'>
              <span>DATE</span>
            </div>
            <div className='col-md-2 ok2land__dashboard-item-cols'>
              <span>FROM</span>
            </div>
            <div className='col-md-2 ok2land__dashboard-item-cols'>
              <span>PLANE</span>
            </div>
            <div className='col-md-1 ok2land__dashboard-item-cols'>
              <span>POB</span>
            </div>
            <div className='col-md-1 ok2land__dashboard-item-cols'>
              <span>FEES</span>
            </div>
            <div className='col-md-3 ok2land__dashboard-item-cols'>
              <span>STATUS</span>
            </div>
          </div>
          <div className='row ok2land__row'>
            {hasLandings &&
              historyLandingsData.map((landing, index) => {
                console.log(index, JSON.stringify(landing));
                if (
                  landing.attributes.permission === true &&
                  new Date(`${landing.attributes.date}T00:00:00.000Z`) < new Date()
                ) {

                  // Passengers info
                  let passengers = [];
                  try {
                    passengers = JSON.parse(landing.attributes.passengers_on_board);
                  } catch (error) {
                    console.error('No passengers.', error);
                  }

                  const passengerCount = passengers && passengers.length ? passengers.length : 0;

                  return (
                    <div key={landing.id} id={landing.id} className='row ok2land__row-item-box'>
                      <div className='col-md-3 date__col'>
                        <span>{new Date(`${landing.attributes.date}T${landing.attributes.time}Z`).toLocaleDateString('en-GB')}</span>
                        <span> • </span>
                        <span>{new Date(`1970-01-01T${landing.attributes.time}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour24: true })}</span>
                      </div>
                      <div className='col-md-2 f14 center__content'>
                        <span>{landing.attributes.from && landing.attributes.from.data && (landing.attributes.from.data.attributes.name1+' '+landing.attributes.from.data.attributes.name2) || '---'}</span>
                      </div>
                      <div className='col-md-2 f14 center__content'>
                        <span>
                          {landing.attributes.aircraft && landing.attributes.aircraft.data && landing.attributes.aircraft.data.attributes.name || '---'}
                        </span>
                      </div>
                      <div className='col-md-1 center__content pob__col'>
                        {( passengerCount > 0 ) && (
                          <React.Fragment>
                            <img src={Pilot} alt='Pilot' />
                            <span>1</span>
                          </React.Fragment>
                        )}
                        {passengerCount > 1 && (
                          <img src={People} alt='People' />
                        )}
                        {passengerCount > 1 && (
                          <span>{passengerCount - 1}</span>
                        )}
                      </div>
                      <div className='col-md-1 f16 lpad center__content'>
                        <span>{landing.attributes.price}€</span>
                      </div>
                      <div className='col-md-3 f16 lpad center__content status__col'>
                        <div className="payment__status">
                          {landing.attributes.paymentStatus == 'Paid' &&
                            <img src={Check} alt="Check"/>
                          }
                          {landing.attributes.paymentStatus != 'Paid' &&
                            <img src={Warning} alt="Warning"/>
                          }
                          <select 
                            className={landing.attributes.paymentStatus == 'Paid' ? 'payment__success' : 'payment__info'} 
                            value={
                              landing.attributes.paymentStatus === 'Paid' ? 'PAID' :
                              landing.attributes.paymentStatus === 'Captive' ? 'CAPTIVE' : 
                              'MISSING PAYMENT'
                            }
                            onChange={(event) => handlePaymentStatus(landing.id, event)}
                          >
                            <option value="PAID">PAID</option>
                            <option value="MISSING PAYMENT">MISSING PAYMENT</option>
                            <option value="CAPTIVE">CAPTIVE</option>
                          </select>
                        </div>
                        <img
                          id={`expand__requests_${index}`}
                          onClick={() => toggleExpand(index)}
                          src={expandIcon[index] || RightArrow}
                          alt='Expand'
                        />
                      </div>
                      {expandedItems[index] && (
                        <div className='col-md-12'>
                          <div className='ok2land__row-expanded'>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Mission</span>
                              <span className='ok2land__ef-content'>{landing.attributes.landingPurpose || 'Landing'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                                <span className='ok2land__ef-title'>Aircraft</span>
                                <span className='ok2land__ef-content'>{landing.attributes.aircraft && landing.attributes.aircraft.data && landing.attributes.aircraft.data.attributes.name || '---'}</span>
                              </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Pilot Name</span>
                              <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.name || '---'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Pilot Email</span>
                              <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.email || '---'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>License #</span>
                              <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.licenceNumber || '---'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Call Sign</span>
                              <span className='ok2land__ef-content'>{landing.attributes.user && landing.attributes.user.data && landing.attributes.user.data.attributes.callSign || '---'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Departure</span>
                              <span className='ok2land__ef-content'>{landing.attributes.departure != null ? new Date(landing.attributes.departure).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour24: true }) : 'N/A'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Arrival</span>
                              <span className='ok2land__ef-content'>{landing.attributes.ata != null ? new Date(landing.attributes.ata).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour24: true }) : 'N/A'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Park time</span>
                              <span className='ok2land__ef-content'>{landing.attributes.time_parked || 'N/A'}</span>
                            </div>
                            <div className='ok2land__expanded-flex'>
                              <span className='ok2land__ef-title'>Flight time</span>
                              <span className='ok2land__ef-content'>{landing.attributes.departure && landing.attributes.ata && calculateFlightTime(landing.attributes.departure, landing.attributes.ata) || '---'}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })
            }
            {!hasLandings && (
              <div className='row ok2land__aircraft-no-up-landings'>
                <div className='col-md-12'>
                  <span>No upcoming landings.</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;